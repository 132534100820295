import {
  elementNotFound,
  getElement,
  getEligibilityCheckoutUrl,
  getVersion,
} from "./helpers";
import { InitializeOptions } from "./types";

export function mount(options: InitializeOptions) {
  const {
    environment,
    merchantId,
    selector,
    amountInCents,
    installmentsCount,
    deferredDays,
    deferredMonths,
    locale,
    captureMethod,
    style,
    onIntegratedPayButtonClicked,
  } = options;
  if (!selector) {
    elementNotFound();
    return false;
  }

  const embeddedUrl = getEligibilityCheckoutUrl(environment ?? "PROD");

  const urlObject = new URL(embeddedUrl);
  urlObject.searchParams.append("merchantId", merchantId);
  urlObject.searchParams.append("amountInCents", amountInCents.toString());
  urlObject.searchParams.append(
    "installmentsCount",
    installmentsCount.toString()
  );
  if (deferredDays) {
    urlObject.searchParams.append("deferredDays", deferredDays.toString());
  }
  if (deferredMonths) {
    urlObject.searchParams.append("deferredMonths", deferredMonths.toString());
  }
  urlObject.searchParams.append("locale", locale ?? "FR");

  urlObject.searchParams.append("captureMethod", captureMethod ?? "automatic");
  urlObject.searchParams.append("domElement", selector);
  urlObject.searchParams.append("style", JSON.stringify(style || null));

  // Regex to match the version in the format "x.y.z"
  urlObject.searchParams.append("inPageVersion", getVersion());
  if (onIntegratedPayButtonClicked) {
    urlObject.searchParams.append("showPayButton", "true");
  }

  injectIframe({
    selector,
    url: urlObject.toString(),
    shouldBeHidden: installmentsCount > 4 && !onIntegratedPayButtonClicked,
  });

  return true;
}

function injectIframe({
  selector,
  url,
  shouldBeHidden,
}: {
  selector: string;
  url: string;
  shouldBeHidden: boolean;
}) {
  const element = getElement(selector);
  const iframe = document.createElement("iframe");
  iframe.src = url;
  iframe.id = `alma-embedded-iframe-${selector}`;
  iframe.style.width = "100%";
  iframe.style.height = "150px";
  iframe.style.transition = "height 0.5s";
  iframe.style.border = "none";
  // `block` removes space under iFrame, `none` hides for credit
  iframe.style.display = shouldBeHidden ? "none" : "block";
  element?.replaceChildren(iframe);
}
