import { ENV, Message, Translations } from "./types";

type Selector = string | null;

export type Store = {
  setEmbeddedSelector: (selector: Selector) => void;
  getEmbeddedSelector: () => Selector;
  getIsCheckoutLoaded: () => boolean;
  setIsCheckoutLoaded: (status: boolean) => void;
  getEnvironment: () => ENV;
  addMessageToSendLater: (message: Message) => void;
  getAndResetMessagesToSendLater: () => Message[];
  setTranslations: (translations: Translations) => void;
  getTranslations: () => Translations | null;
};

export const createStore: (environment?: ENV) => Store = (
  environment = "LIVE"
) => {
  let embeddedSelector: Selector = null;
  let isCheckoutLoaded: boolean = false;
  let messagesToSendLater: Set<Message> = new Set(); // Use set to deduplicate the startPayment action.
  let translations: Translations | null = null;

  const setEmbeddedSelector = (selector: Selector) => {
    embeddedSelector = selector;
  };

  const getEmbeddedSelector = () => {
    return embeddedSelector;
  };

  const setIsCheckoutLoaded = (status: boolean) => {
    isCheckoutLoaded = status;
  };

  const getIsCheckoutLoaded = () => {
    return isCheckoutLoaded;
  };

  const getEnvironment = () => {
    return environment;
  };

  const addMessageToSendLater = (message: Message) => {
    messagesToSendLater.add(message);
  };

  const getAndResetMessagesToSendLater = () => {
    const previousMessages = Array.from(messagesToSendLater);
    messagesToSendLater = new Set();

    return previousMessages;
  };

  const setTranslations = (newTranslations: Translations) => {
    translations = newTranslations;
  };

  const getTranslations = () => {
    return translations;
  };

  return {
    setEmbeddedSelector,
    getEmbeddedSelector,
    setIsCheckoutLoaded,
    getIsCheckoutLoaded,
    getEnvironment,
    addMessageToSendLater,
    getAndResetMessagesToSendLater,
    setTranslations,
    getTranslations,
  };
};
