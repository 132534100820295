import { MODAL_ID_PREFIX } from "../constants";

const ALMA_COLORS = {
  orange: "#FA5022",
  backdrop: "#6C6C6C",
  white: "#FEFEFE",
};

export const style = `
#${MODAL_ID_PREFIX}-element {
  position: fixed;
  z-index: 10337;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

#${MODAL_ID_PREFIX}-background {
  background: ${ALMA_COLORS.backdrop};
  position: absolute;
  width: 100vw;
  height: 100vh;
  opacity: 0.8;
}

#${MODAL_ID_PREFIX}-body {
  background: ${ALMA_COLORS.white};
  width: 100%;
  max-width: 700px;
  height: calc(100% - 32px);
  bottom: 0;
  position: absolute;
  border-radius: 0;
  box-shadow: 0px 0px 12px 4px rgba(41, 15, 8, 0.04);
  border-radius: 20px 20px 0 0;
  overflow: hidden;
}

#${MODAL_ID_PREFIX}-close {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  width: 72px;
  height: 72px;
}

#${MODAL_ID_PREFIX}-close:hover, #${MODAL_ID_PREFIX}-close:focus-visible {
    border-radius: 50%;
    outline: 1px solid ${ALMA_COLORS.orange};
    outline-offset: -16px;
}

#${MODAL_ID_PREFIX}-logo {
  position: absolute;
  left: 24px;
  top: 16px;
}

#${MODAL_ID_PREFIX}-iframe {
  width: 100%;
  height: 100%;
  border: 0;
}

.alma-fixed-body {
  position: fixed;
  width: 100%;
  overflow: auto;
  height: 100%;
}

.${MODAL_ID_PREFIX}-slideIn {
  animation: ${MODAL_ID_PREFIX}-slideIn 300ms ease-out;
  animation-iteration-count: 1;
}

.${MODAL_ID_PREFIX}-fadeIn {
  animation: ${MODAL_ID_PREFIX}-fadeIn 300ms;
  animation-iteration-count: 1;
}

.${MODAL_ID_PREFIX}-slideOut {
  animation: ${MODAL_ID_PREFIX}-slideOut 300ms ease-in;
  animation-iteration-count: 1;
}

.${MODAL_ID_PREFIX}-fadeOut {
  animation: ${MODAL_ID_PREFIX}-fadeOut 300ms;
  animation-iteration-count: 1;
}

@media (min-width: 600px) {
  #${MODAL_ID_PREFIX}-body {
    width: 80%;
    height: 80%;
    max-height: 700px;
    bottom: initial;
    border-radius: 20px;
  }
}

@keyframes ${MODAL_ID_PREFIX}-slideIn {
  from {
    transform: translateY(300px);
  }

  to {
    transform: translateY(0px);
  }
}

@keyframes ${MODAL_ID_PREFIX}-fadeIn {
  from {
    opacity: 0;
    visibility: hidden;
  }

  to {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes ${MODAL_ID_PREFIX}-slideOut {
  from {
    transform: translateY(0px);
  }

  to {
    transform: translateY(300px);
  }
}

@keyframes ${MODAL_ID_PREFIX}-fadeOut {
  from {
    opacity: 1;
    visibility: visible;
  }

  to {
    opacity: 0;
    visibility: hidden;
  }
}
`;
