import { getCheckoutUrlBasedOnEnv } from "../helpers";
import { ENV, MessageType, Translations } from "../types";

type Subscriber = {
  eventName: string;
  callerName: string;
  callback: () => void;
};

export function startListener(
  env: ENV,
  domElementSelector: string,
  setTranslations: (translations: Translations) => void
) {
  const inPageStatusSubscribers: Array<Subscriber | null> = [];

  const listener = (event: MessageEvent<any>) => {
    // Handle messages only if origin is correct.
    if (event.origin !== getCheckoutUrlBasedOnEnv(env)) {
      return false;
    }

    if (event.data.from !== "checkout") {
      return false;
    }

    const payload = event.data.payload;
    const eventDomElement = event.data.domElement;

    if (event.data.type === "in_page_status") {
      // Only those 2 events have dom element attached
      if (
        (payload === "ready_for_payment" || payload === "can_open_modal") &&
        domElementSelector !== eventDomElement
      ) {
        return;
      }
      inPageStatusSubscribers
        .filter((subscriber) => subscriber && subscriber.eventName === payload)
        .forEach((subscriber) => {
          subscriber && subscriber.callback();
        });
    } else if (event.data.type === "embedded_height") {
      if (domElementSelector !== eventDomElement) {
        return;
      }
      const iframe = document.getElementById(
        `alma-embedded-iframe-${domElementSelector}`
      );
      if (iframe) {
        iframe.style.height = `${Math.ceil(payload)}px`;
      }
    } else if (event.data.type === "translations") {
      setTranslations(payload);
    } else {
      console.warn("Unknown message type:", event.data.type);
    }
  };

  window.addEventListener("message", listener, false);

  function unsubscribe() {
    window.removeEventListener("message", listener, false);
  }

  function onInPageStatusChanged(
    eventName: MessageType,
    callerName: string,
    callback: () => void
  ) {
    const previousIndex = inPageStatusSubscribers.findIndex(
      (subscriber) =>
        subscriber &&
        subscriber.eventName === eventName &&
        subscriber.callerName === callerName
    );

    if (previousIndex) {
      inPageStatusSubscribers[previousIndex] = null;
    }

    inPageStatusSubscribers.push({ eventName, callerName, callback });
  }

  return {
    onInPageStatusChanged,
    unsubscribe,
  };
}
